.summaryPointsContainer{
    position: fixed;
    transition: all 0.7s;
}
.summaryPoints,
.formContainer{
    background-color: var(--gray-white);
    box-shadow: 0 2px 30px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 20px ;
    min-width: 85%;
    max-width: 85%;
    margin-left: 5%;
}
h2{text-align: center;}

.formContainer {
    display: flex;
    flex-direction: column;
  }
  
.formRow {
    margin-bottom: 20px;
    text-align: center;
}
  
.inputData {
    position: relative;
    
}
  
.inputData input{
    width: 80%;
    padding: 10px 0;
    margin: 5px 0 15px 0;
    display: inline-block;
    border: none;
    border-bottom: 1px solid var(--color-blue);
    background-color: transparent;
}
  
.inputData input:focus {
    outline: none;
    border-bottom: 1px solid var(--color-black);
}
  
.inputData label {
    position: absolute;
    top: 0;
    left: 10%;
    transform: translateX(0);
    pointer-events: none;
    transition: 0.2s ease all;
    color: #999;
    font-size: 25px;
}

.inputData input:focus ~ label,
.inputData textarea:focus ~ label,
.inputData input:valid ~ label,
.inputData textarea:valid ~ label {
    top: -20px;
    font-size: 15px;
    color: var(--color-blue);
}

button {
    padding: 10px 20px;
    background-color: var(--color-blue);
    border: none;
    border-radius: 5px;
    color: var(--gray-white);
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 40px;
    width: fit-content;
    font-size: var(--font-size-xl);
    box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.05);
}

button:hover {
    background-color: var(--color-black);
}

.scrollProgressBarContainer{
    width: 93%;
    margin-left: 3%;
}

@media screen and (width < 1200px) {
    .summaryPointsContainer{
        position: initial !important;
        transform: translate(0, 0) !important;
        display: block;
    }

    .summaryPoints,
    .formContainer,
    .scrollProgressBarContainer{
        margin: 0 !important;
        width: 97% !important;
        max-width: 100% !important;
    }
}