
.blogsContainer{
    position: relative;
    padding: 0 8rem;
    width: calc(100% - 16rem);
    z-index: 1;   
    background-color: var(--gray-white);
    font-family: var(--font-lato);
}
.blogsGrid{
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 10px; 
    width: 100%; 
    margin: 0 auto; 
    
}

.sectionTitle{
    font-size: var(--font-size-71xl);
    margin-bottom: 5px;
    text-align: center;
}

.sectionSubtitle{
    margin-top: 5px;
    font-size: var(--font-size-51xl);
    color: var(--color-dimgray);
    font-weight: 400;
    text-align: center;
}

.otherNewsTitle{
    font-size: 32px;
    text-align: start;
}

.blogItem{
    height: 300px;
    text-align: center; 
    box-shadow: 0 2px 30px 20px rgba(0, 0, 0, 0.05);
    border-radius: var(--br-11xl);
    background-color: var(--gray-white);
    position: relative;
    overflow: hidden;
}

.blogItemGradient{
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.014), rgba(0, 0, 0, 0.616));
    position: relative;
}

.blogItemGradient:hover{
    background-image: linear-gradient(rgba(2, 0, 0, 0), rgba(0, 0, 0, 0.075));
}

.blogItem:first-of-type{
    height: 100%;
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
}

.blogItem img,
.otherPostsGrid .blogItem:first-of-type .itemInfo .img{
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translate(-50%, 0);
    

}

.itemInfo,
.otherPostsGrid .blogItem:first-of-type .itemInfo{
    background-image: linear-gradient(rgba(0, 0, 0, 0.021), rgb(0, 0, 0));
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    justify-content: end;
 
}

.itemInfo .categoryTag,
.otherPostsGrid .blogItem:first-of-type .itemInfo .categoryTag{
    display: block;
    background-color: var(--color-blue);
    color: var(--gray-white);
    border-radius: 20px;
    width: fit-content;
    padding: 5px;
    font-size: 15px;
    z-index: 1;
}

.itemInfo .title,
.otherPostsGrid .blogItem:first-of-type .itemInfo .title{
    width: 80%;
    display: block;
    color: var(--gray-white);
    text-decoration: none;
    text-align: start;
    font-size: 25px;
    z-index: 1;
}


.title:hover,
.otherPostsGrid .blogItem:first-of-type .itemInfo .title:hover{
    color: rgb(0, 153, 255);
}

.itemInfo .publishedOn,
.otherPostsGrid .blogItem:first-of-type .itemInfo .publishedOn{
    font-size: 15px;
    color: rgb(214, 214, 214);
    text-align: start;
    z-index: 1;
}

.blogItem:first-of-type .itemInfo{
    background-image: linear-gradient(rgba(0, 0, 0, 0.041), rgba(0, 0, 0, 0.781));
    position: relative;
    width: 100%;
    height: 100%;
    padding:0 40px ;
    
}

.blogItem:first-of-type .itemInfo .categoryTag{
    display: block;
    background-color: var(--color-blue);
    color: var(--gray-white);
    border-radius: 20px;
    width: fit-content;
    padding: 5px;
    font-size: 15px;
}

.blogItem:first-of-type .itemInfo .title{
    width: 90%;
    display: block;
    color: var(--gray-white);
    text-decoration: none;
    text-align: start;
    font-size: var(--font-size-21xl);
}

.title:hover,
.blogItem:first-of-type .itemInfo .title:hover{
    color: rgb(0, 153, 255);
}

.blogItem:first-of-type .itemInfo .publishedOn{
    font-size: 19px;
}


/* --------------Other news---------------------- */

.otherPostsGrid{
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    grid-template-rows: repeat(2, 1fr);
    gap: 10px; 
    width: 100%; 
    margin: 0 auto;
}

.otherPostsGrid .blogItem:first-of-type{
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
}

.otherPostsGrid .blogItem:first-of-type .itemInfo .title{
    font-size: 25px;
}

.showMore{
    display: block;
    color: rgb(82, 82, 82);
    text-decoration: none;
    text-align: center;
    font-size: 30px;
}

.showMore:hover{
    color: rgb(0, 153, 255);
}

/* -------------------------Most searched tags ---------------- */
.mostSearchedTagsContainer{
    padding: 30px 50px;
    margin: 30px auto 0;
    text-align: start;
    background-color: var(--gray-white);
    box-shadow: 0 2px 30px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    width: fit-content;
}

.mostSearchedTagsContainer h5{
    display: inline-block;
    margin: 0;
    font-size: 25px;
}

.mostSearchedTag{
    display: inline-block;
    font-size: 16px;
    background-color: rgb(231, 231, 231);
    padding: 7px 15px;
    border-radius: 10px;
    margin-left: 7px;
}

.mostSearchedTag:hover{
    cursor: pointer;
    background-color: rgb(194, 194, 194);
}

@media screen and (width < 1500px) {
    .blogItem:first-of-type .itemInfo .title{
        width: 90%;
    }
}

@media screen and (width < 1280px) {
    .blogsContainer{
        padding: 0 0;
        width: 100% ;
    }
    .blogsGrid{
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        grid-template-rows: repeat(4, 50vw);
        gap: 10px; 
        width: 100%; 
        margin: 0 auto; 
    }

    .blogItem{
        height: 100%;
    }

    .blogItem:first-of-type{
        height: 100%;
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
    }

    .otherPostsGrid{
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        grid-template-rows: repeat(4, 50vw);
        gap: 10px; 
        width: 100%; 
        height: 100%;
        margin: 0 auto;
    }
}

@media screen and (width < 700px) {
    .blogsGrid,
    .otherPostsGrid{
        grid-template-rows: repeat(4, 60vw);   
    }

    .itemInfo .title,
    .otherPostsGrid .blogItem:first-of-type .itemInfo .title{
        font-size: 19px;
    }

   

    .itemInfo .publishedOn,
    .otherPostsGrid .blogItem:first-of-type .itemInfo .publishedOn{
        font-size: 14px;
    }

    .blogItem:first-of-type .itemInfo .publishedOn{
        font-size: 16px;
    }
    .blogItem:first-of-type .itemInfo .title{
        font-size: 25px;
    }
}