.container{
    font-family: var(--font-lato);
}
.contactContainer{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    width: 80%;
    margin: 80px auto;
}

.infoContainer{
    grid-column: 1 / span 5;
}

.contactFormContainer{
    grid-column: 6 / span 7;
}

.contactCard{
    padding: 50px 80px;
    margin: 15px auto;
    width: fit-content;
    box-shadow: 0 2px 30px 20px rgba(0, 0, 0, 0.05);
    border-radius: var(--br-11xl);
    background-color: black;
    color: var(--gray-white);
}

.sectionTitle h1{
    margin: 3px 0;
    font-size: 37px;
}

.contactAdresses{
    margin: 80px 0;
}

.address:first-child .addressIcon{
    margin-bottom: 5px;
}

.address:first-child .addressInfo{
    text-align: start;
}

.addressIcon{
    display: inline-block;
    font-size: 23px;
    margin-bottom: -5px;
}

.addressInfo{
    display: inline-block;
    font-size: 17px;
    margin-left: 20px;
}

.contactSocials{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.socialIcon{
    font-size: 45px;
    color: white;
    transition: 0.2s all;
}

.socialIcon:hover{
    color: var(--color-blue);
}

.socialIcon:active{
    transform: scale(80%);
}

@media screen and (width < 1500px) {
    .infoContainer{
        grid-column: 1 / span 6;
    }
    
    .contactFormContainer{
        grid-column: 7 / span 6;
    }
}

@media screen and (width < 1100px) {
    .infoContainer{
        grid-column: 3 / span 8;
    }
    
    .contactFormContainer{
        grid-column: 3 / span 8;
    }
}

@media screen and (width < 900px) {
    .infoContainer{
        grid-column: 1 / span 12;
    }
    
    .contactFormContainer{
        grid-column: 1 / span 12;
    }
    .socialIcon{
        font-size: 35px;
    }
}

@media screen and (width < 470px) {
    .contactCard{
        padding: 40px ;
    }
    .addressInfo{
        display: inline-block;
        font-size: 15px;
        margin-left: 10px;
    }
}