.cookieContainer{
    display: grid;
    grid-template-columns: 40% 60%;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 80%;
    height: 80vh;
    background: linear-gradient(45deg, rgba(128, 128, 128, 0.808)0 29%,rgba(255, 255, 255, 0.381) 30% 31% , rgba(128, 128, 128, 0.808)32% 35% , rgba(255, 255, 255, 0.381) 36% 38%,  rgba(128, 128, 128, 0.627) 40% 93%, rgba(255, 255, 255, 0.381) 94% 95%, rgba(128, 128, 128, 0.627) 96% 100%);
    backdrop-filter: blur(7px);
    z-index: 99999;
    border-radius: 30px;
    overflow: hidden;
    align-items: center;
    color: white;
    animation: showCookie 5s;
}

.cookieContainer div h3{
    width: 80%;
}

.cookieContainer div button:last-child{
    background-color: #fff;
    color: var(--color-blue);
}

@keyframes showCookie {
    0% {
        bottom: -100%;
    }
    80% {
        bottom: -100%
    }
    100% {
        bottom: 30px
    }
}

@media screen and (max-width: 1500px){
    .cookieContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 90%;
    }

    .cookieContainer img{
        width: 300px;
    }

    .cookieContainer div{
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .cookieContainer div button{
        width: 250px;
    }

    .cookieContainer div button:last-child{
        margin-top: 10px;
    }
}

@media screen and (max-width: 1000px){
    .cookieContainer{
        width: 90%;
    }

    .cookieContainer div h3{
        width: 95%;
    }

    .cookieContainer img{
        width: 200px;
    }
}

@media screen and (max-width: 800px){
    .cookieContainer div h3{
        font-size: 16px;
    }

    .cookieContainer div h1{
        padding: 10px;
        text-align: center;
    }
}
