.productContainer{
    width: 80%;
    display: grid;
    margin: 70px auto;
    border-radius: var(--br-11xl);
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    box-shadow: 0 2px 30px 20px rgba(0, 0, 0, 0.05);
    padding: 40px 70px;
}

.leftSide{
    column-span: 1 / span 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: var(--font-lato);
}

.logoGrid h1{
    font-size: 45px;
    padding: 0;
    margin: 0;
    background-image: linear-gradient(to right, rgba(19, 19, 19, 0.589), black);
    backdrop-filter: blur(100%);
    background-clip: text;
    color: transparent;
    font-weight: 900;
    text-shadow: 2px 2px 2px rgba(128, 128, 128, 0.548);
}

.messageGrid h1{
    width: 90%;
    font-size: 70px;
    word-spacing: 4px;
    font-size: 70px;
    letter-spacing: 2px;
    line-height: 80px;
}

.rightSide{
    column-span: 2 / span 1;
}

.rightSide img{
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
    border-radius: var(--br-11xl);
}

.button{
    background-color: var(--color-blue);
    font-size: 35px;
}

@media screen and (width < 1200px) {
    .messageGrid h1{
        font-size: 50px;
        letter-spacing: 0px;
        word-spacing: 0px;
        line-height: normal;
        width: 100%;
    }
    .logoGrid h1{
        font-size: 35px;
    }
    .button{
        font-size: 30px;
    }
}
@media screen and (width < 900px) {
    .messageGrid h1{
        font-size: 35px;
    }
    .logoGrid h1{
        font-size: 30px;
    }
    .button{
        font-size: 20px;
    }
}
@media screen and (width < 700px) {
    .productContainer{
        grid-template-rows: repeat(2, 1fr);
        padding: 10px;
        width: 95%;
    }
    .leftSide{
        grid-column: 1 / span 2;
        text-align: center;
    }
    .rightSide{
        grid-column: 1 / span 2;
    }

    .messageGrid h1{
        font-size: 70px;
    }
    .logoGrid h1{
        font-size: 40px;
    }
    .button{
        font-size: 30px;
    }
}
@media screen and (width < 500px) {
    .messageGrid h1{
        font-size: 55px;
    }
    .logoGrid h1{
        font-size: 30px;
    }
    .button{
        font-size: 25px;
    }
}