.container{
    font-family: var(--font-lato);
}
.aboutContainer{
    width: 70%;
    margin: 50px auto;
}

.row{
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 20px; 
    margin-bottom: 70px;
}

.bento{
    box-shadow: 0 2px 30px 0 rgba(0,0,0,.05);
    padding: 1.4rem;
    background-color: #fff;
    border-radius: 20px;
}

.bentoTitle{
    color: purple;
    font-size: 25px;
    margin: 10px;
    margin-left: 0;
}

.title{
    font-size: 55px;
    font-weight: 900;
    margin: 10px 0;
}

.description{
    font-size: 22px;
    color: rgb(83, 83, 83);
}

.generalInfo{
    height: calc(100% - 45px);  
    position: relative;
}

.generalInfo .bentoTitle{
    font-size: 27px;
}

.generalInfo .title{
    font-size: 65px;
}

.generalInfo .description{
    font-size: 21px;
    width: 90%;
    position: absolute;
    bottom: 15px;
} 

.devidedCol img{
    width: 100%;
    height: 400px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.historyInfo{
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 4%;
}

.historyCard{
    display: grid;
    grid-template-columns: 30% 70%;
    background-color: rgb(247, 247, 247);
    align-items: center;
    border-radius: 20px;
    margin-bottom: 10px;
    padding: 10px;
}

.historyCard svg{
    font-size: 50px;
}

.historyCard div h3{
    margin: 0;
    font-size: 40px;
    font-weight: 900;
}

.historyCard .description{
    margin: 0;
    font-size: 18px;
}

.infoCard:first-child{
    background: linear-gradient(200deg, rgba(255, 184, 0, 0.50) 0%, rgba(255, 102, 16, 0.50) 42.06%, rgba(255, 39, 78, 0.50) 76.33%, rgba(255, 46, 171, 0.50) 100%);
    color: white;
}

.infoCard:first-child .description{
    color: rgb(237, 237, 237);
    font-weight: 600;
}

@media screen and (max-width: 1500px){
    .aboutContainer{
        width: 80%;
    }
    
}

@media screen and (max-width: 1250px){
    .aboutContainer{
        width: 90%;
    }
    
}

@media screen and (max-width: 1000px){
    .title{
        font-size: 45px;
    }

    .description{
        font-size: 18px;
    }

    .generalInfo .title{
        font-size: 50px;
    }

    .generalInfo .description{
        font-size: 18px;
    }

    .devidedCol img{
        height: 300px;        
    }
    
    .historyCard svg{
        font-size: 40px;
    }

    .historyCard div h3{
        font-size: 30px;
    }

    .historyCard div .description{
        font-size: 16px;
    }
}

@media screen and (max-width: 800px){
    .row{
        grid-template-columns: 100%;
        margin-bottom: 20px;
    }

    .bento{
        margin-bottom: 20px;
    }

    .generalInfo .description{
        position: inherit;
        margin-top: 30px;
    }

    .devidedCol img{
        height: 500px;  
        margin: 20px 0;      
    }
}

@media screen and (max-width: 800px){
    .title{
        font-size: 40px;
    }

    .description{
        font-size: 16px;
    }

    .generalInfo .title{
        font-size: 45px;
    }

    .generalInfo .description{
        font-size: 16px;
    }

    .devidedCol img{
        height: 400px;        
    }
    
    .historyCard svg{
        font-size: 30px;
    }

    .historyCard div h3{
        font-size: 25px;
    }

    .historyCard div .description{
        font-size: 14px;
    }
}

