.gradientsBackground {
  background: linear-gradient(200deg, rgba(255, 184, 0, 0.50) 0%, rgba(255, 102, 16, 0.50) 42.06%, rgba(255, 39, 78, 0.50) 76.33%, rgba(255, 46, 171, 0.50) 100%);
  color: var(--gray-white);
}

.gridBox {
  display: grid;
}

.blackBackgorund {
  background: var(--color-black);
  padding: 0;
  transition: transform 0.6s ease-in-out;
}

.image {
  width: 100%;
  height: 255px;
  object-fit: contain;
}

.avatarBg{
  width: auto;
  height: 255px;
  margin-left: -33px;
  margin-top: -33px;
  object-fit: contain;
}

.productivityBox {
  background: var(--color-black);
  color: var(--gray-white);
}

.matrixBackground {
  background: var(--gray-white);
  transition: transform 0.3s ease-in-out;
}

.slide {
  transform: translateX(0);
}


@media screen and (600px <= width < 960px) {
  .image {
    height: 200px;
  }
}

@media screen and (960px <= width < 1280px) {
  .image {
    height: 168px;
  }
}

@media screen and (1840px <= width < 2000px) {
  .image {
    height: 280px;
  }
}

@media screen and (width >= 2000px) {
  .image {
    height: 370px;
  }

  .avatarBg{
    height: 370px;
  }
}