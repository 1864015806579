@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-lato: Lato;

  /* font sizes */
  --font-size-sm: 14px;
  --font-size-41xl: 60px;
  --font-size-51xl: 70px;
  --font-size-61xl: 80px;
  --font-size-71xl: 90px;
  --font-size-91xl: 120px;
  --font-size-101xl: 150px;
  --font-size-111xl: 180px;
  --font-size-31xl: 50px;
  --font-size-xl: 20px;
  --font-size-21xl: 40px;
  --font-size-base: 16px;

  /* Colors */
  --gray-white: #fff;
  --gray-800: #1b114a;
  --gray-300: #e4e6f1;
  --gray-500: #6b7094;
  --color-black: #000;
  --color-dimgray: #686868;
  --color-gray: rgba(255, 255, 255, 0.5);
  --color-deeppink: #f62472;
  --color-khaki: #e4d96f;
  --color-yellowgreen: #a1df2b;
  --color-blue: #0029ff;
  --color-text: #0065FF;

  /* Gaps */
  --gap-21xl: 40px;
  --gap-9xl: 28px;
  --gap-5xl: 24px;
  --gap-13xl: 32px;
  --gap-47xl: 66px;

  /* Paddings */
  --padding-12xs: 1px;
  --padding-base: 16px;
  --padding-6xl: 25px;
  --padding-12xl: 31px;

  /* border radiuses */
  --br-11xl: 30px;

  --contactus-bg: '/contactus.png'
}

code {
  font-family: var(--font-lato);
}

::-webkit-scrollbar {
  width: 5px;
}
  ::-webkit-scrollbar-track {
  background: transparent; 
}
  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius:10px;
    transition: 0.3s all ease-in-out;
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
} 