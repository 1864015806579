
.background {
  width: calc(100% - 4rem);
  height: calc(100% - 6rem);
  padding: 3rem 2rem;
  z-index: 2;
}

.image {
  position: absolute;
  top: -3rem;
  left: -2rem;
  width: calc(100% + 4rem);
  height: calc(100% + 6rem);
  z-index: 1;
  object-fit: cover;
}

.title {
  font-size: 2.3rem;
  font-weight: 800;
  z-index: 3;
}

.subTitle {
  font-size: 2.3rem;
  font-weight: 600;
  z-index: 3;
}

.button {
  position: absolute;
  bottom: 13%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.button > span {
  color: var(--color-text);
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

@media screen and (width < 600px) {
  .title {
    font-size: 26px;
  }
  
  .subTitle {
    font-size: 26px;
  }
}


@media screen and (600px <= width < 960px) {
  .image {
    position: absolute;
    top: -3rem;
    left: -3rem;
    width: calc(100% + 6rem);
    height: calc(100% + 6rem);
    z-index: 1;
    object-fit: cover;
  }

  .title {
    font-size: 1.5rem;
  }
  
  .subTitle {
    font-size: 1.5rem;
  }
}

@media screen and (960px <= width < 1280px ) {
  .title {
    font-size: 1.8rem;
  }
  
  .subTitle {
    font-size: 1.8rem;
  }
}

@media screen and (1280px <= width < 2000px ) {

}

@media screen and (width >= 2000px ) {
  .image {
    top: -3rem;
    left: -3rem;
    width: calc(100% + 6rem);
    height: calc(100% + 6rem);
  }

  .background {
    padding: 4rem 3rem;
  }

  .title {
    font-size: 2.8rem;
  }
  
  .subTitle {
    font-size: 2.8rem;
  }

  .button {
    padding: 1.3rem 2.5rem;
  }
}