.privacyPolicyContent h1{
    color: var(--color-black);
    text-align: center;
    font-family: Lato;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.contentContainer{
    display: block;
    width: 60%;
    margin: 20px auto;
    font-size: 25px;
    min-height: 60vh;
}

.videoBg {
    position: absolute;
    width: 100% !important;
    height: 117.854px !important;
    top: 4px;
    left: 0;
    z-index: 1;
    overflow: hidden;
  }

  .videoBg > video {
    object-fit: cover;
    object-position: center;
    border-radius: 30px;
  }
  