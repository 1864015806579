.gridContainer {
  width: 100%;
  height: auto;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
}

.noGutters {
  padding: 0;
  width: 100%;
}

.gridItem {
  display: grid;
  padding: 0.5rem;
}