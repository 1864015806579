.becomeClientContent{
    cursor: pointer;
    transition: color .2s ease-in-out;
    text-decoration: none;
    color: var(--color-black);
}
.becomeClientButton{
    border-radius: 40px;
    background-color: var(--color-blue);
    width: 160px;
    padding: 18px 1rem;
    font-size: var(--font-size-xl);
    box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.05);
    transition: all 500ms;
}
.becomeClientButton{
    cursor: pointer;
}
.becomeClientButton a{
    color: var(--gray-white);
}

.becomeClientButton:hover{
    background-color: var(--color-black);
}
