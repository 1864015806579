.productivityBox {
  background: var(--color-black);
  color: var(--gray-white);
}

.langBox {
  background: var(--color-white);
  color: var(--gray-black);
}

.langInfoBox {
  box-shadow: none !important;
}

.contactusImage {
  position: relative;
  width: 100%;
  height: 110%;
  object-fit: cover;
  cursor: pointer;
}

.contactusBox {
  padding: 0px;
  margin: 0px;
}

.contactusStack {
  padding: 0px;
  margin: 0px;
}

.langlogosImg {
  width: 85%;
  height: 255px;
  object-fit: contain;
}

