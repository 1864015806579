.section {
  padding: 2rem 0;
  width: 100%;
}

.gridBox {
  padding: 0 5rem;
  width: calc(100% - 10rem);
}

.introText {
  margin-bottom: 5rem;
}

@media screen and (width < 600px) {
  .gridBox {
    padding: 0;
    width: 100%;
  }
}


@media screen and (600px <= width < 960px) {
  .gridBox {
    padding: 0 1rem;
    width: calc(100% - 2rem);
  }
}

@media screen and (960px <= width < 1280px ) {
  .gridBox {
    padding: 0 1rem;
    width: calc(100% - 2rem);
  }
}

@media screen and (1280px <= width < 1800px ) {
  
}

@media screen and (1800px <= width < 2000px ) {
  .gridBox {
    padding: 0 10rem;
    width: calc(100% - 20rem);
    gap: 1.5rem !important;
  }
}

@media screen and (width >= 2000px ) {
  .gridBox {
    padding: 0 15rem;
    width: calc(100% - 30rem);
    gap: 2rem !important;
  }
}