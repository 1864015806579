.container {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 1rem 6rem;
  padding-bottom: 0;
  padding-top: 1.5rem;
  background: var(--gray-white);
  z-index: 1;
}

.gridContainer {
  border-bottom: 1px solid var(--gray-300);
  padding: 3rem 0;
}

.gridItem {
  margin: auto 0 ;
}

.logoBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
  align-items: flex-start;
}

.logoBox > p {
  margin: 0;
  font-size: var(--font-size-sm);
  text-align: start;
  line-height: 26px;
}

.copyright {
  width: 100%;
  display: grid;
  place-items: center;
  padding: 3rem;
}

.copyright > span {
  margin: 0;
  font-size: var(--font-size-sm);
}

@media screen and (width < 600px) {
  .container {
    padding: 1rem;
    margin-top: 0;
  }

  .gridContainer {
    padding: 0;
    padding-bottom: 3rem;
  }

  .gridItem {
    margin: auto;
    margin-bottom: 2rem;
    
  }
  .gridList ul li a{
    font-size: xx-small;
   
  }

  .gridList ul li{
    padding-bottom: 7px !important;
  }

  .logoBox {
   justify-content: center;
   align-items: center;
  }

  .gridList{
    display: grid;
    place-items: center;
   
  }



  .copyright {
    padding: 3rem 0;
  }
}


@media screen and (600px <= width < 960px) {
  .container {
    padding: 1rem;
    margin-top: 0;
  }

  .gridContainer {
    padding: 0;
    padding-bottom: 3rem;
  }

  .gridItem {
    margin: auto;
    margin-bottom: 2rem;
  }

  .logoBox {
   justify-content: center;
   align-items: center;
  }

  .gridList{
    display: grid;
    
    place-items: center;
  }



  .copyright {
    padding: 3rem 0;
  }
}

@media screen and (1840px <= width < 2000px) {
  .logoBox > p {
    font-size: var(--font-size-xl);
    line-height: 33px;
  }

  .copyright > span {
    font-size: var(--font-size-base);
  }
}

@media screen and (width >= 2000px) {
  .logoBox > p {
    font-size: var(--br-11xl);
    line-height: 44px;
  }

  .copyright {
    padding: 5rem 0;
  }

  .copyright > span {
    font-size: var(--br-11xl);
  }
}