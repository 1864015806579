.blogPostContainer{
    position: relative;
    padding: 3rem 8rem;
    margin: 0 auto;
    width: calc(100% - 16rem);
    z-index: 1;   
    background-color: var(--gray-white);
    display: grid;
    grid-template-columns: repeat(12, 1fr); 
    column-gap: 10px;
    font-family: var(--font-lato);
}

.content{
    grid-column: 1 / span 9;
}

.summaryPoints{
    grid-column: 10 / span 2;
    height: 100vh;
    position: absolute;
    bottom: 0;
    width: 210%;
}



.blogPostContainer img{
    display: block;
    width: 75%;
    height: auto;
    margin: 30px auto;
}

.blogPostContainer h1{
    font-size: 40px;
    text-align: center;
    margin-bottom: 5px;
    width: 80%;
    margin: 10px auto;
}

.contentBody{
    display: block;
    margin: 40px auto;
    width: 75%;
    font-size: 19px;
}

.socialIcons{
    float: right;
    
}
.socialIcons a{
    color: black;
    text-decoration: none;
    margin-right: 5px;
    
}

.socialIcons a:hover{
    color: var(--color-blue);
}

@media screen and (width < 1200px) {
    .blogPostContainer img{
        width: 80%;
    }

    .contentBody{
        width: 100%;
    }
    .content{
        grid-column: 1 / span 12;
    }
    .summaryPoints{
        grid-column: 1 / span 12 !important;
        margin: 30px !important;
        position: inherit !important;
        margin: 0 auto !important;
        width: 100% !important;
        position: initial !important;
        height: fit-content;
    }
}

@media screen and (width < 900px) {
    .blogPostContainer{
        width: 90%;
        padding: 3rem 0;
       
    }
    .blogPostContainer img{
        width: 90%;
    }

    .contentBody{
        width: 85%;
    }
    
    .summaryPoints{
        max-width: 85%;
        margin-top: 15px !important;
    }
}

@media screen and (width < 650px) {
    .blogPostContainer{
        width: 100%;
        padding: 3rem 0;
       
    }
    .blogPostContainer img{
        width: 95%;
    }

    .contentBody{
        width: 90%;
    }
}