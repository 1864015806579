.navbarContainer {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  padding-top: 2rem;
  z-index: 999;
}

.menu {
  border-radius: 40px;
  background-color: var(--gray-white);
  width: fit-content;
  padding: 0 3rem;
  height: 60px;
  font-size: var(--font-size-xl);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.05);
}

.menuItem {
  cursor: pointer;
  transition: color .2s ease-in-out;
  text-decoration: none;
  color: var(--color-black);
}

.menuItem:hover {
  color: var(--color-blue);
}

.active {
  display: flex;
  align-items: center;
  height: 100%;
  text-align: center;
  border-bottom: 3px solid #007AFE;
}

.space { 
  width: 87px;
}

@media screen and (width < 600px) {
  .navbarContainer {
    justify-content: center;
  }

  .menuIcon {
    cursor: pointer;
  }

  .menuItem:not(:last-child) {
    margin-bottom: 2rem;
  }

  .menuItem:first-child {
    margin-top: 2rem;
  }
}