:root{
  --blue: #3D8AFF;
  --purple: #FE53B9;
}

.parallaxBodyIntersecting {
  width: 100%;
  height: 200vh;
  position: relative;
  overflow: hidden;
}

.textIntersecting {
  mix-blend-mode:lighten;
  background-color: white;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  transition: 0.3s all ease-in-out;
  font-size: var(--font-size-111xl);
  font-weight: 900;
  line-height: 16rem;
  /* text-shadow: 1px 1px 2px black; */
}

.textContentIntersecting{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.gradientIntersecting {
  background-image: linear-gradient(#3D8AFF, #FE53B9 30% 50%, #3D8AFF 90%);
  height: 100%;
  width: 100%;
}

/* //-------------------------------------------------- */
.parallaxBody {
  width: 100%;
  height: fit-content;
  position: relative;
  overflow: hidden;
}

.text {
  mix-blend-mode:lighten;
  background-color: white;
  width: 100%;
  height: 100vh;
  position: relative;
  transition: 0.3s all ease-in-out;
  font-size: var(--font-size-111xl);
  font-weight: 900;
  line-height: 16rem;
  /* text-shadow: 1px 1px 2px black; */
}

.textContent{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.gradient {
  background-image: linear-gradient(#3D8AFF, #FE53B9 30% 50%, #3D8AFF 90%);
  height: 90%;
  width: 90%;
  position: fixed;
  top: 5%;
  left: 5%;
  
}



@media screen and (width < 600px) {
  .text,
  .textIntersecting {
    font-size: var(--font-size-51xl);
    line-height: 6.5rem;
  }
}

@media screen and (1840px <= width < 2000px) {
  .text,
  .textIntersecting {
    font-size: 120px;
    line-height: 13rem;
  }
}

@media screen and (width >= 2000px) {
  .text,
  .textIntersecting {
    font-size: 134px;
    line-height: 14rem;
  }
}