.drawer {
  position: fixed;
  width: calc(100% - 6rem);
  height: calc(100vh - 5rem);
  top: 0;
  right: 0;
  z-index: 9999;
  padding: 3rem;
  padding-top: 2rem;
  transform: translateX(100%);
  transition: 0.4s all ease-in-out;
  background-color: var(--gray-300);
  box-shadow: 0 2px 30px 20px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  font-family: var(--font-lato);
}

.open {
  transform: translateX(0px);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}

.cross {
  display: block;
  width: 100%;
  text-align: end;
  cursor: pointer;
}

.cross > svg {
  width: 22px;
  height: 22px;
  transition: 0.5s all ease-in-out;
}

.cross:hover > svg {
  fill: var(--color-blue);
}