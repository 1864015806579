.container{
  position: relative;
  width: calc(100% - 12rem);
  height: fit-content;
  padding: 7rem 6rem;
  display: grid;
  place-items: center;
  background: var(--gray-white);
  z-index: 1;
}

.data {
  width: calc(100% - 1rem);
  height: fit-content;
}

@media screen and (width < 600px) {
  .container{
    padding: 7rem 0;
    width: 100%;
  }
}

@media screen and (600px <= width < 960px) {
  .container{
    padding: 7rem 0;
    width: 100%;
  }
}


@media screen and (960px <= width < 1280px) {
  .container{
    padding:8rem 0;
    width: 100%;
  }
}

@media screen and (1840px <= width < 2000px) {
  .container{
    padding: 9rem 8rem;
    width: calc(100% - 16rem);
  }
}

@media screen and (width >= 2000px) {
  .container{
    padding: 9rem 8rem;
    width: calc(100% - 16rem);
  }
}