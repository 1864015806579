
.formContainerHidden {
  overflow: hidden;
  margin: auto;
  border-radius: 8px;
  background-color: var(--gray-white);
  box-shadow: 0 2px 30px 20px rgba(0, 0, 0, 0.05);  
  position: fixed;
  bottom: 90px;
  right: -100%;
  z-index: 999;
  transition: all 1s ease;
  text-align: center;
}

.formContainerBlock{
  position: initial !important;
  text-align: center;
} 

.formContainerHidden.show{
  width: fit-content;
  padding: 10px;
  right: 20px;
}

.title{
  width: 80%;
  padding: 0 30px;
  font-size: 30px;
}

.formContainerBlock .title{
  font-size: 40px;
  font-weight: 700;
  margin: 30px auto;
}

.contactForm {
  display: flex;
  flex-direction: column;
}

.formRow {
  margin-bottom: 20px;
}

.inputData {
  position: relative;
}

.inputData input,
.inputData textarea {
  width: 80%;
  padding: 10px 0;
  margin: 5px 0 15px 0;
  display: inline-block;
  border: none;
  border-bottom: 1px solid var(--color-blue);
  background-color: transparent;
}

.inputData input:focus,
.inputData textarea:focus {
  outline: none;
  border-bottom: 1px solid var(--color-black);
}

.inputData label {
  position: absolute;
  top: 0;
  left: 10%;
  pointer-events: none;
  transition: 0.2s ease all;
  color: #999;
  font-size: 25px;
}
  
.inputData input:focus ~ label,
.inputData textarea:focus ~ label,
.inputData input:valid ~ label,
.inputData textarea:valid ~ label {
  top: -20px;
  font-size: 15px;
  color: var(--color-blue);
}
  
textarea {
  height: 100px;
}

button {
  padding: 10px 20px;
  background-color: var(--color-blue);
  border: none;
  border-radius: 5px;
  color: var(--gray-white);
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 40px;
  width: fit-content;
  font-size: var(--font-size-xl);
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.05);
}

button:hover {
  background-color: var(--color-black);
}

.formToggler{
  position: fixed;
  bottom: 8px;
  right: 10px;
  cursor: pointer;
  z-index: 1000;
  background-color: transparent;
  color: var(--color-black);
  font-size: 70px;
  padding: 0;
}

.formToggler img{width: 90px;}

.animateToggle{
  animation: infiniteBounce 10s infinite;
}

.formToggler:hover{
  background-color: transparent;
}

.errorsContainer{
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
  text-align: start;
}

.errorsContainer p{
  background-color: #B00020;
  color: var(--gray-white);
  padding: 5px 20px;
  margin: 5px 0;
  border-radius: 20px;
  font-size: small;
}

.successContainer{
  width: 340px;
  color: var(--gray-white);
}

.successContainer p{
  background-color: #22bb33;
  padding: 5px 2px;
  margin: 5px 0;
  border-radius: 20px;
  font-size: smaller;
  height: 80px;
  animation: slideMessage 1s 1;
}

.loadingContainer{
    width: 280px;
    padding: 200px 10px;
    color: var(--gray-white);
    
}

.loadingContainer div{
  margin: 0 auto;
}

@keyframes slideMessage {
  from{
    margin-right: -160%;  
  }
  to{
    margin-right: 0;
  }
}

@keyframes infiniteBounce{
  0%{
      bottom: 10px;
  }
  3%{
      bottom: 40px;
  }
  6%{
      bottom: 28px;
  }
  9%{
      bottom: 35px;
  }
  12%{
      bottom: 22px;
  }
  13%{
      bottom: 25px;
  }
  15%{
      bottom: 20px;
  }
  100%{
      bottom: 20px;
  }
}

@media screen and (width < 600px) {
  .formContainerHidden{
    right: -600px;
    bottom: 0;
    width: 100%;
    height: 100vh;
  }

  .formContainerHidden.show{
    right: 0;
    padding: 0;
  }

  .formContainerBlock .title{
    font-size: 30px;
    font-weight: 700;
    margin: 30px auto;
  }

  .formToggler{display: none;}

  .formContainerHidden.show .formToggler{display: block;}
}