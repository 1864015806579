.codeEditor {
  background-color: var(--color-black);
  color: var(--gray-white);
  margin: 20px 0;
  font-family: var(--font-lato) !important;
  font-size: 15px;
  white-space: pre-wrap;
  text-align: left;
  line-height: 21px;
  overflow: hidden;
  
}
.hideScrollBar{
  height: 286px;
  width: 370px;
  overflow: scroll;
  margin-right: -6px;
}

.codeContent.animate{
  animation-name: scroll-code;
  animation-duration: 2s;
}

.pink {
  color: var(--color-deeppink);
}

.green {
  color: var(--color-yellowgreen);
}

.yellow {
  color: var(--color-khaki);
}


@keyframes scroll-code {
  0%{
    margin-top: 0;
  }
  80%{
    margin-top: -180%;
  }
  100%{
    margin-top: 0;
  }
}

@media screen and (1840px <= width < 2000px) {
  .codeEditor {
    font-size: var(--font-size-xl);
    line-height: 26px;
  }
}

@media screen and (width >= 2000px) {
  .codeEditor {
    font-size: var(--br-11xl);
    line-height: 36px;
  }
}