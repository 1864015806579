.yokrLogoIcon {
  width: 87px;
  height: 31px;
}

@media screen and (width < 600px) {
  .yokrLogoIcon {
    width: 77px;
    height: 21px;
  }
}

@media screen and (1840px <= width < 2000px) {
  .yokrLogoIcon {
    width: 100px;
    height: 44px;
  }
}