.loadingContainer{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: -1;
    animation: showScreen 8s;
}

.counter{
    position: absolute;
    z-index: 9999;
    top: 23px;
    right: 10%;
}

.loadingPercentage{
    position: absolute;
    top: calc(50% - 250px);
    left: calc(50% - 250px);
    text-align: center;
}

.loadingAnimationImage{
    z-index: 9999;
}

.loadingImageContainer{
    height: 100%;
    overflow: hidden;
    height: 0px;
    animation: showImage 7s 1;
}

.blackCircle{
    width: 0px;
    height: 0px;
    background-color: black;
    position: absolute;
    z-index: 99999;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    animation: showCircle 6.8s 1;
}

@keyframes showCircle {
    0%{
        width: 0px;
        height: 0px;
    }
    90%{
        width: 0px;
        height: 0px;
        background-image: rgb(0, 0, 0);
    }
    100%{
        width: 2000px;
        height: 2000px;
        background-color: rgba(0, 0, 0, 0.096);
    }
}

@keyframes showImage {
    0%{
        height: 0px;
    }
    70%{
        transform: scale(100%);
    }
    75%{
        transform: scale(200%);
    }
    95%{
        transform: scale(0);
    }
    100%{
        height: 500px;
        transform: scale(0);
    }
}


@keyframes showScreen {
    0%{
        background-color: white;
        z-index: 999;
    }
    80%{background-color: rgb(255, 255, 255);}
    
    100%{
      background-color: transparent;
    }
    

}

@media screen and (width < 960px) {
    .counter{
        top: 75px;
        right: 50%;
        transform: translate(50%, 0);
    }
}