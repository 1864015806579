.container {
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.05);
  padding: 0.8rem;
  width: calc(100% - 1.6rem);
  height: calc(100% - 1.6rem);
  border-radius: 30px;
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F4F4F4;
  color: #0029FF;
  border-radius: 30px;
  font-weight: 700;
  font-size: 16px;
  width: fit-content;
  height: 40px;
  padding: 0rem 2.3rem;
}

.infoBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.3rem;
  height: calc(100% - 40px);
  padding: 0;
  margin: 0;
}

.h5 {
  font-size: 34px;
  font-weight: 900;
  margin: 0;
  padding: 0;
}

.p {
  font-size: 20px;
  padding: 0;
  margin: 0;
  line-height: 28px;
}

.link {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 800;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  letter-spacing: 0.4px;
  text-decoration: none;
  color: var(--color-black);
}

.image {
  width: 100%;
  height: 100%;
  max-width: 250px;
  max-height: 250px;
  object-fit: cover;
  object-position: center;
  border-radius: 30px;
  margin-left: auto;
}

@media screen and (width < 600px) {

  .tag {
    font-size: 12px;
    width: fit-content;
    height: 30px;
    padding: 0rem 1.8rem;
  }

  .container {
    padding: 1rem;
  }

  .infoBox {
    margin-top: 1rem;
    gap: 1rem;
  }
  
  .h5 {
    font-size: 22px;
  }
  
  .p {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }
  
  .link {
    font-size: 16px;
  }
}


@media screen and (600px <= width < 800px) {
  .container {
    padding: 1rem;
  }

  .infoBox {
    margin-top: 1.5rem;
    gap: 1.5rem;
  }
  
  .h5 {
    font-size: 28px;
  }
  
  .p {
    text-align: center;
    font-size: 18px;
    line-height: 28px;
  }
  
  .link {
    font-size: 16px;
  }
}

@media screen and (800px <= width < 960px ) {

  .tag {
    font-size: 12px;
    width: fit-content;
    height: 30px;
    padding: 0rem 1.8rem;
  }

  .infoBox {
    gap: 0.8rem;
    margin-top: 0.5rem;
  }
  
  .h5 {
    font-size: 20px;
  }
  
  .p {
    font-size: 14px;
    line-height: 24px;
  }
  
  .link {
    font-size: 14px;
  }
}

@media screen and (960px <= width < 1280px ) {
  .infoBox {
    gap: 1rem;
  }
  
  .h5 {
    font-size: 26px;
  }
  
  .p {
    font-size: 16px;
    line-height: 24px;
  }
  
  .link {
    font-size: 16px;
  }
}

@media screen and (1280px <= width < 2000px ) {

}

@media screen and (width >= 2000px ) {

}